import { AVAILABLE_IMAGE_FORMATS, IMAGE_VALIDATION_ERRORS, AVAILABLE_IMAGE_EXTENSIONS } from '@/shared/const';
import ImageModel from '@/shared/components/multiple-photo-selector/ImageModel';
import { generateGuid } from '@/util/utils';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { getImageById } from '@/shared/api-modules/application.api';
export default defineComponent({
    props: {
        value: {
            type: Array,
            default: () => []
        },
        onLoaded: {
            type: Function,
            default: () => { }
        },
        isShouldReset: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        mainButtonLabel: {
            type: [String, Object],
            default: ''
        },
        entityType: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const errorMessage = ref('');
        const gridSize = 3;
        const images = ref([]);
        const nativeInput = ref();
        const dragover = ref(false);
        const advertisedDragover = ref(false);
        const advertisedImage = computed(() => {
            return images.value.find(image => image.advertised);
        });
        const nonAdvertisedImages = computed(() => {
            return images.value.filter(image => !image.advertised);
        });
        const availableImageExtensions = computed(() => {
            return AVAILABLE_IMAGE_EXTENSIONS.join(', ');
        });
        const processFiles = files => {
            if (files.length) {
                errorMessage.value = '';
            }
            let fileCount = 0;
            Array.from(files).forEach(file => {
                if (fileCount >= 10 || images.value.length >= 10) {
                    errorMessage.value = root.$t('common.errors.image.image_max_photos');
                    root.$scrollTo('.photo-selector', 1500);
                    return;
                }
                const newFile = new ImageModel({ type: props.entityType });
                newFile.uuid = generateGuid();
                newFile.file = file;
                newFile.advertised = !images.value.length;
                fileCount += 1;
                images.value.push(newFile);
            });
        };
        watch(() => images.value.map(image => image.id), newValue => {
            if (newValue.every(el => el)) {
                emit('input', images.value.filter(image => image.id).map(image => image.id));
            }
        });
        watch(() => images.value.map(image => image.error), newValue => {
            if (newValue.some(el => el)) {
                const value = newValue.find(el => el);
                switch (value) {
                    // Remove unused validation after testing
                    case IMAGE_VALIDATION_ERRORS.TOO_BIG:
                        errorMessage.value = root.$t('common.errors.image.too_big');
                        break;
                    case IMAGE_VALIDATION_ERRORS.UNAVAILABLE_FORMAT: {
                        const availableFormats = Object.keys(AVAILABLE_IMAGE_FORMATS).join(', ');
                        errorMessage.value = root.$t('common.errors.image.image_wrong_format', { available_formats: availableFormats });
                        break;
                    }
                    case IMAGE_VALIDATION_ERRORS.WRONG_ASPECT_RATIO:
                        errorMessage.value = root.$t('common.errors.image.image_wrong_aspect_ratio');
                        break;
                    case IMAGE_VALIDATION_ERRORS.WRONG_SIZE:
                        errorMessage.value = root.$t('common.errors.image.image_wrong_size');
                        break;
                    default:
                        // TODO: recheck
                        console.log('Default case [false]');
                        return false;
                }
                return true;
            }
        });
        const removeImage = imageParam => {
            const imageIndex = images.value.findIndex(image => image.uuid === imageParam.uuid);
            if (images.value[imageIndex].advertised && images.value[imageIndex + 1]) {
                images.value[imageIndex + 1].advertised = true;
            }
            images.value.splice(imageIndex, 1);
            let errorImageFound = false;
            images.value.forEach(image => {
                if (image.error) {
                    errorImageFound = true;
                }
            });
            if (!errorImageFound) {
                errorMessage.value = '';
            }
            emit('input', images.value.filter(image => image.id).map(image => image.id));
        };
        const setAdvertisedImageByUUID = uuid => {
            let advertisedImage;
            let advertisedImageIndex;
            images.value.forEach((image, index) => {
                const imageCopy = image;
                imageCopy.advertised = imageCopy.uuid.toString() === uuid.toString();
                if (imageCopy.advertised) {
                    advertisedImage = imageCopy;
                    advertisedImageIndex = index;
                }
            });
            images.value.splice(advertisedImageIndex, 1);
            images.value.unshift(advertisedImage);
            emit('input', images.value.filter(image => image.id).map(image => image.id));
        };
        const advertisedDrop = e => {
            e.preventDefault();
            advertisedDragover.value = false;
            if (e.dataTransfer.getData('text/plain')) {
                setAdvertisedImageByUUID(e.dataTransfer.getData('text/plain'));
            }
        };
        const selectPhotos = () => {
            if (images.value.length > 10) {
                errorMessage.value = root.$t('common.errors.image.image_max_photos');
                root.$scrollTo('.photo-selector', 1500);
                return;
            }
            nativeInput.value.click();
        };
        const findNonAdvertisedImage = (rowIndex, colIndex) => {
            return nonAdvertisedImages.value[rowIndex * gridSize + colIndex];
        };
        const onImageDragStart = (ev) => {
            ev.dataTransfer.dropEffect = 'move';
            ev.dataTransfer.effectAllowed = 'move';
            ev.dataTransfer.setData('text/plain', ev.target.id);
        };
        const dragLeave = () => {
            dragover.value = false;
        };
        const onImageDrop = (ev, rowIndex, colIndex) => {
            const imageDrop = findNonAdvertisedImage(rowIndex, colIndex);
            if (!imageDrop)
                return;
            const imageDropIndex = images.value.findIndex(el => el.uuid === imageDrop.uuid);
            const imageID = ev.dataTransfer.getData('text/plain');
            const imageDrag = images.value.find(item => item.uuid === imageID);
            const imageDragIndex = images.value.findIndex(item => item.uuid === imageID);
            images.value.splice(imageDropIndex, 1, imageDrag);
            images.value.splice(imageDragIndex, 1, imageDrop);
            emit('input', images.value.filter(image => image.id).map(image => image.id));
        };
        const dragOver = (e) => {
            e.preventDefault();
            dragover.value = true;
        };
        const advertisedDragLeave = () => {
            advertisedDragover.value = false;
        };
        const advertisedDragOver = (e) => {
            e.preventDefault();
            advertisedDragover.value = true;
        };
        const imagesSelected = () => {
            processFiles(nativeInput.value.files);
            nativeInput.value.value = null;
        };
        const drop = (e) => {
            e.preventDefault();
            dragover.value = false;
            processFiles(e.dataTransfer.files);
        };
        watch(() => props.value, async () => {
            const currentImageIds = images.value.map(image => image.id);
            let _images = [];
            for (const imageId of props.value) {
                const id = typeof imageId === 'object' && imageId !== null
                    ? imageId.image_id
                    : imageId;
                if (props.isShouldReset || !currentImageIds.includes(id)) {
                    const image = await getImageById(id);
                    if (image.detail !== 'not found') {
                        const fileModel = new ImageModel();
                        fileModel.url = image.image_url;
                        fileModel.uuid = generateGuid();
                        fileModel.id = id;
                        fileModel.advertised =
                            id === (props.value[0].image_id || props.value[0]);
                        _images.push(fileModel);
                    }
                }
            }
            if (_images.length > 0) {
                images.value = _images;
                props.onLoaded();
            }
        }, { immediate: true });
        return {
            advertisedDragover,
            advertisedImage,
            availableImageExtensions,
            errorMessage,
            gridSize,
            images,
            nativeInput,
            nonAdvertisedImages,
            advertisedDragLeave,
            advertisedDragOver,
            advertisedDrop,
            dragLeave,
            dragOver,
            drop,
            findNonAdvertisedImage,
            imagesSelected,
            onImageDragStart,
            onImageDrop,
            removeImage,
            selectPhotos
        };
    }
});
